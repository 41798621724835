import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/account/login'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/account/login'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/account/register'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/deposit',
    name: 'asset',
    component: () => import('@/views/asset/deposit'),
  },
  {
    path: '/withdraw',
    name: 'asset',
    component: () => import('@/views/asset/withdraw'),
  },
  {
    path: '/missions',
    name: 'missions',
    component: () => import('@/views/missions/missions'),
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/order/order'),
  },
  {
    path: '/order/details',
    name: 'order',
    component: () => import('@/views/order/details'),
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/my/my'),
  },
  {
    path: '/my/setting',
    name: 'my',
    component: () => import('@/views/my/setting'),
  },
  {
    path: '/my/setting_name',
    name: 'my',
    component: () => import('@/views/my/setting_name'),
  },
  {
    path: '/my/realname',
    name: 'my',
    component: () => import('@/views/my/realname'),
  },
  {
    path: '/my/setting_email',
    name: 'my',
    component: () => import('@/views/my/setting_email'),
  },
  {
    path: '/my/bank',
    name: 'my',
    component: () => import('@/views/my/bank'),
  },
  {
    path: '/my/add_bank',
    name: 'my',
    component: () => import('@/views/my/add_bank'),
  },
  {
    path: '/my/setting_login_pwd',
    name: 'my',
    component: () => import('@/views/my/setting_login_pwd'),
  },
  {
    path: '/my/setting_pay_pwd',
    name: 'my',
    component: () => import('@/views/my/setting_pay_pwd'),
  },
  {
    path: '/my/message',
    name: 'my',
    component: () => import('@/views/my/message'),
  },
  {
    path: '/my/article',
    name: 'my',
    component: () => import('@/views/my/article'),
  },
  {
    path: '/my/deposit_record',
    name: 'my',
    component: () => import('@/views/my/deposit_record'),
  },
  {
    path: '/my/withdraw_record',
    name: 'my',
    component: () => import('@/views/my/withdraw_record'),
  },
  {
    path: '/my/team',
    name: 'my',
    component: () => import('@/views/my/team'),
  },
  {
    path: '/my/invite',
    name: 'my',
    component: () => import('@/views/my/invite'),
  },
]
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})

export default router
